.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

body {
  background-color: #f7f2ee !important;
  font-family:'Josefin Sans', cursive !important;
  font-size: larger !important;
}

@media only screen and (max-width: 600px) {
  body {
  font-size: smaller !important;
  }
  .footer {
    font-size: x-small !important;
  }
}

.textpadding {
  padding-left: 50px;
  padding-right: 50px;
  text-align: justify;
}

h1 {
  margin-bottom: 50px;
  color: #a68376;
  text-align: center;
}

.subheading {
  min-height: 100px;
}

.center {
  align-items: center;
  width: 500px;
}

.animated {
  background-color: #a68376;
  animation-duration: 4s;
  width: 30%;
  margin-left: 35%;
  margin-bottom: 50px;
  height:1px !important;
  animation-name: running;
  animation-delay: 2s;
  animation-iteration-count: 10;
}

@keyframes running {
  from {width: 0%}
  to {width:30%}
}

.footer{
  font-size: small;
  background:#a68376;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  color: white;
}

.footer .rightside{
  text-align: right;
}

.header_nav{
  background: white !important;

}

a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: white !important;
}

/* selected link */
a:active {
  color: white;
}

.listbox {
 
  border-style: solid;
  margin: 5%;
  padding: 25px;
  background-color: #f2edd8;
  text-align: left;
}

.list{
  list-style-type: "\2714";
}

.listbox li {
  padding-left: 20px;
}

.text{
  text-align: justify;
  margin:5%;
}

.form{
  
  padding-left: 5%;
}

.list-group-item.active{
  background-color: #f2edd8 !important;
  border-color:#f2edd8 !important;
  color:black !important;

}

.right{
  float: right;
  width:40%;
  border-radius: 15px;
  margin: 15px;
}
