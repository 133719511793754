.hero {
    position: relative;
    text-align: center;
    color: white;
}

.toptext {
    position: absolute;
    color: black;
    top: 15%;
    margin-right: 10%;
    margin-left: 50%
}

@media only screen and (max-width: 750px) {
   
    #welkombtn {display: none;}
  }

.bottomleft {
    position: absolute;
    left: 0%;
    bottom: 25%;
    margin-right: 50%;
    height: 100%;
    border-radius: 5px;
    opacity: 0.8;
}

.bottomright {
    position: absolute;
    right: 10%;
    bottom: 15%;
    margin-left: 50%;
    transform: translate(-50%, 0%);
    
}

img.backgroundimage {
    width: 100%;
    opacity:0.2;

}

.googlemap{
    display: flex;
    align-items: center;
    justify-content: center;
}

.text a:hover{
    color: red !important;
}

.text a:link{
    color: red !important;
}
.text a:visited{
    color: red !important;
}
