.navigation {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 0.5rem 0rem;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
    z-index: 100;
  }

  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  .brand-name {
    text-decoration: none;
    font-size: 1.3rem;
    margin-left: 1rem;
    border: solid;
    border-radius: 15px;
    background-color: #f2edd8;
    color: #a68376 !important;
  }
  
  .navigation-menu {
    margin-left: auto;
    margin-top:15px;
  }
  
  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }

  .navigation-menu li .active{
    border-bottom: solid;
    border-bottom-right-radius: 15px;
  }
  
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }
  a.menu {
    padding: 5px;
    color: black;
    text-decoration: none;
}

    a.menu:hover {
        color: black !important;
        border-bottom: solid;
        border-bottom-right-radius: 15px;
    }

  
  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  
  .hamburger:hover {
    background-color: #2642af;
  }
  
  @media screen and (max-width: 830px) {
    .hamburger {
      display: block;
    }
  
    .navigation-menu ul {
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      height: calc(100vh - 60px);
      background-color: white;
      border-top: 1px solid black;
      display: none;
    }
  
    .navigation-menu {
      text-align: center;
      margin: 0;
    }
  
    .navigation-menu a {
      color: black;
      width: 100%;
      padding: 1rem !important;
    }
  
    .navigation-menu li:hover {
      background-color: #f2edd8;
    }
  
    .navigation-menu.expanded ul {
      display: block;
      height: auto;

    }
  }
  