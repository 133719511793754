.react-calendar-datetime-picker {
    margin: auto;
    width: 100%;
    padding: 25px
}

.react-calendar-datetime-picker .dtWrapper {
    margin: auto;
    position: relative
}

.react-calendar-datetime-picker .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #a68376;
    padding: 1.5rem 1rem;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.react-calendar-datetime-picker .header .header-date {
    display: flex;
    color: white;
    font-size: 1.125rem;
    justify-content: center;
    flex-wrap: wrap
}

.react-calendar-datetime-picker .header .header-date .header-date--month {
    margin-left: .5rem;
    cursor: pointer
}

.react-calendar-datetime-picker .header .header-date .header-date--year {
    cursor: pointer
}

.react-calendar-datetime-picker .header .header-date .header-date--day {
    font-size: 24px;
    text-align: center;
    width: 100%;
    margin-top: .5rem
}

.react-calendar-datetime-picker .header .header--btn {
    cursor: pointer;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    color: white
}

.react-calendar-datetime-picker .header .header--btn.is-disabled {
    opacity: 0.2;
    pointer-events: none
}

.react-calendar-datetime-picker .header .header--clearBtn {
    position: absolute;
    top: 4px;
    left: 4px;
    color: white;
    width: 14px;
    height: 14px;
    cursor: pointer;
    opacity: 0.8
}

.react-calendar-datetime-picker .is-rtl .header .header-date {
    direction: ltr
}

.react-calendar-datetime-picker .is-rtl .header .header--btn {
    transform: scale(-1)
}

.react-calendar-datetime-picker .yearWrapper {
    position: relative
}

.react-calendar-datetime-picker .yearWrapper:after, .react-calendar-datetime-picker .yearWrapper:before {
    content: '';
    display: block;
    width: 100%;
    height: 1.875rem;
    position: absolute;
    left: 0;
    transition: 0.4s;
    transition-delay: 0.2s
}

.react-calendar-datetime-picker .yearWrapper:before {
    background-image: linear-gradient(0deg, #fff, #fff 10%, rgba(245, 245, 245, 0));
    bottom: 0
}

.react-calendar-datetime-picker .yearWrapper:after {
    background-image: linear-gradient(180deg, #fff, #fff 10%, rgba(245, 245, 245, 0));
    top: -0.1em
}

.react-calendar-datetime-picker .yearGrid {
    list-style: none;
    overflow: hidden;
    padding: 0;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    height: 18.1875rem;
    overflow-y: scroll;
    padding: 1.875rem 0;
    margin: .5rem 0
}

.react-calendar-datetime-picker .yearGrid .is-year-list {
    display: block
}

.react-calendar-datetime-picker .yearGrid::-webkit-scrollbar {
    display: none
}

.react-calendar-datetime-picker .yearGrid_year {
    width: 20%;
    height: 3.4375rem;
    line-height: 3.4375rem;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer
}

.react-calendar-datetime-picker .is-selectedYearRef {
    color: #a68376;
    font-weight: bold;
    font-size: 1.375rem
}

.react-calendar-datetime-picker .yearGrid_year:nth-child(5n+1) {
    clear: left
}

.react-calendar-datetime-picker .is-year-list .yearGrid_year {
    width: 100%
}

.react-calendar-datetime-picker .monthList {
    list-style: none;
    overflow: hidden;
    padding: 0;
    text-align: center;
    will-change: transform;
    transition: 800ms
}

.react-calendar-datetime-picker .monthList_month {
    width: calc(100% / 3);
    height: 4.5rem;
    line-height: 4.5rem;
    float: left;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    color: #666;
    transition: color 200ms ease-in-out
}

.react-calendar-datetime-picker .monthList_month:hover {
    color: #444
}

.react-calendar-datetime-picker .monthList_month.is-disabled {
    opacity: 0.2;
    pointer-events: none
}

.react-calendar-datetime-picker .monthList_month.is-selected-month {
    color: #03bf1f;
    font-weight: bold
}

.react-calendar-datetime-picker .monthList_month:nth-child(3n+1) {
    clear: left
}

.react-calendar-datetime-picker .is-rtl.monthList .monthList_month {
    float: right
}

.react-calendar-datetime-picker .is-rtl.monthList .monthList_month:nth-child(3n+1) {
    clear: right
}

.react-calendar-datetime-picker .daysList {
    list-style: none;
    overflow: hidden;
    padding: .5rem 0;
    margin: 0;
    text-align: center
}

.react-calendar-datetime-picker .daysList .daysList-day {
    width: calc(100% / 7);
    height: calc(310px / 7);
    line-height: calc(310px / 7);
    float: left;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 50%;
    color: #666;
    font-size: .875rem;
    margin: .0625rem 0
}

.react-calendar-datetime-picker .daysList .is-week-days {
    color: #aaa;
    font-size: 14px;
    font-weight: 500;
    height: 34px;
    line-height: 34px;
    border-radius: 0
}

.react-calendar-datetime-picker .daysList .is-prev-month {
    border-radius: 0
}

.react-calendar-datetime-picker .daysList .is-next-month {
    border-radius: 0
}

.react-calendar-datetime-picker .daysList .is-border-right-0 {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%
}

.react-calendar-datetime-picker .daysList .is-border-left-0 {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%
}

.react-calendar-datetime-picker .daysList .daysList-day:nth-child(7n+1) {
    clear: left
}

.react-calendar-datetime-picker .daysList .is-today {
    box-shadow: inset 0px 0px 0px 2px #029418
}

.react-calendar-datetime-picker .daysList .is-disabled {
    pointer-events: none;
    background-color: #e4ebe5;
    color: #aebfa9
}

.react-calendar-datetime-picker .daysList .is-disabled-by-user {
    pointer-events: none;
    opacity: 0.2
}

.react-calendar-datetime-picker .daysList .is-minMaxDisabled {
    pointer-events: none;
    opacity: 0.2
}

.react-calendar-datetime-picker .daysList .is-selected-day {
    background-color: #a68376;
    color: white
}

.react-calendar-datetime-picker .daysList .is-selected-day-from {
    background-color: #03bf1f;
    border-radius: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    color: white
}

.react-calendar-datetime-picker .daysList .is-selected-day-to {
    background-color: #03bf1f;
    border-radius: 0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    color: white
}

.react-calendar-datetime-picker .daysList .is-selected-day-from.is-selected-day-to {
    border-radius: 50% !important
}

.react-calendar-datetime-picker .daysList .is-selected-day-range {
    color: #008213;
    border-radius: 0;
    background-color: #a0f5ad
}

.react-calendar-datetime-picker .daysList .is-pointer {
    cursor: pointer
}

.react-calendar-datetime-picker .daysList .is_weekends {
    color: #d20000 !important
}

.react-calendar-datetime-picker .is-rtl.daysList .daysList-day {
    float: right
}

.react-calendar-datetime-picker .is-rtl.daysList .daysList-day:nth-child(7n+1) {
    clear: right
}

.react-calendar-datetime-picker .is-rtl.daysList .is-selected-day-from {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%
}

.react-calendar-datetime-picker .is-rtl.daysList .is-selected-day-to {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%
}

.react-calendar-datetime-picker .is-rtl .daysList-day {
    float: right
}

.react-calendar-datetime-picker .is-rtl .daysList-day:nth-child(7n+1) {
    clear: right
}

.react-calendar-datetime-picker .is-rtl .is-selected-day-from {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%
}

.react-calendar-datetime-picker .is-rtl .is-selected-day-to {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%
}

.react-calendar-datetime-picker .is-rtl .daysList .is-border-right-0 {
    border-radius: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%
}

.react-calendar-datetime-picker .is-rtl .daysList .is-border-left-0 {
    border-radius: 0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%
}

.react-calendar-datetime-picker .is-rtl .daysList .is-selected-day-from {
    border-radius: 0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%
}

.react-calendar-datetime-picker .is-rtl .daysList .is-selected-day-to {
    border-radius: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%
}

.react-calendar-datetime-picker .calender-modal {
    position: absolute;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
    font-family: inherit;
    border-radius: .25rem;
    overflow: hidden;
    width: 310px
}

.react-calendar-datetime-picker .calender-modal.is-calender {
    position: relative
}

.react-calendar-datetime-picker .input-picker {
    position: relative;
    width: 100%
}

.react-calendar-datetime-picker .input-picker .input-picker--input {
    cursor: pointer;
    width: 100%;
    text-align: center;
    line-height: 1.625rem;
    height: 1.625rem;
    padding: 0 8px;
    border-radius: .25rem;
    border-width: .0625rem
}

.react-calendar-datetime-picker .input-picker .input-picker--input:focus {
    outline: none
}

.react-calendar-datetime-picker .input-picker .input-picker--clearBtn {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    top: 50%;
    right: .3125rem;
    transform: translateY(-50%);
    cursor: pointer;
    color: #c9c9c9
}

.react-calendar-datetime-picker .todayBtn {
    display: block;
    text-align: center;
    margin: auto;
    border-radius: 16px;
    border: .0625rem solid #009a17;
    width: 5rem;
    padding: .25rem 0;
    transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
    font-size: .875rem;
    font-weight: 400;
    margin-bottom: .25rem;
    cursor: pointer
}

.react-calendar-datetime-picker .time {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 .5rem .5rem
}

.react-calendar-datetime-picker .time .field-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    direction: ltr !important
}

.react-calendar-datetime-picker .time .time-fieldset {
    padding: 0;
    border: 0;
    display: flex;
    flex-direction: column-reverse
}

.react-calendar-datetime-picker .time .time-fieldset.is-disabled {
    pointer-events: none;
    opacity: 0.5
}

.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--dec, .react-calendar-datetime-picker .time .time-fieldset .time-fieldset--inc {
    width: 1rem;
    height: 1rem;
    padding: 0;
    margin: auto;
    border: 0;
    cursor: pointer;
    opacity: 0.3;
    position: relative
}

.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--dec:focus, .react-calendar-datetime-picker .time .time-fieldset .time-fieldset--inc:focus {
    outline: none
}

.react-calendar-datetime-picker .time .time-fieldset .time-fieldset--dec svg, .react-calendar-datetime-picker .time .time-fieldset .time-fieldset--inc svg {
    transform: translate(-50%, -50%) scale(0.75);
    position: absolute;
    top: 50%;
    left: 50%
}

.react-calendar-datetime-picker .time .time--title {
    margin-right: .5rem;
    color: #aaa;
    min-width: 2rem;
    text-align: center
}

.react-calendar-datetime-picker .time .time--input {
    margin: 0 .25rem;
    text-align: center;
    width: 1.25rem;
    appearance: textfield;
    border: 0;
    color: #666;
    font-size: .875rem
}

.react-calendar-datetime-picker .time .time--input:focus {
    outline: none
}

.react-calendar-datetime-picker .time .time--input::-webkit-inner-spin-button, .react-calendar-datetime-picker .time .time--input::-webkit-outer-spin-button {
    -webkit-appearance: none
}

.react-calendar-datetime-picker .is-rtl .time {
    direction: rtl
}

.react-calendar-datetime-picker .is-rtl .time--title {
    margin-left: .5rem
}

.react-calendar-datetime-picker .is-ltr .time {
    direction: ltr
}

.react-calendar-datetime-picker .is-ltr .time--title {
    margin-right: .5rem
}
